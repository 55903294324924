import {request} from "@/network/request";
import da from "element-ui/src/locale/lang/da";

export function personalInformation(data){
    return request({
        url:'/user/selectUserByPhone',
        method:'get',
        params:data
    })
}

export function updateMsg(data){
    return request({
        url:'/user/updateUser',
        method:'post',
        params:data
    })
}

export function updatePwd(data){
    return request({
        url:'/user/updatePassword',
        method:'post',
        params:data
    })
}

export function upLoad(data){
    return request({
        url:'/user/uploadAvatar',
        method:'post',
        data:data,
    })
}
