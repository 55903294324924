<template>
    <div class="container">
        <v-snackbar
                timeout="2000"
                :value="snackbarText"
                :color="snackbarColor"
                absolute
                top
                v-model="snackbarShow"
        >{{ snackbarText }}
        </v-snackbar>
        <h4 class="title">基本信息</h4>
        <v-divider></v-divider>
        <div class="msg_container">
            <!-- 头像-->
            <div class="icon mb-7">
                <div class="mr-4">头像</div>
                <el-upload
                        class="avatar-uploader"
                        action=""
                        :http-request="upLoadAvatar"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
            <v-row>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--账号-->
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2">姓名</div>
                        <div class="input_width">
                            <v-text-field
                                    label="请输入"
                                    solo
                                    dense
                                    :value="userMsg.userName"
                                    v-model="userMsg.userName"
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>
                <!--生日-->
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2">生日</div>
                        <div class="input_width">
                            <v-text-field
                                    type="date"
                                    label="请输入"
                                    solo
                                    dense
                                    :value="userMsg.birthday"
                                    v-model="userMsg.birthday"
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--性别-->
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2">性别</div>
                        <div class="input_width">
                            <v-select
                                    :items="['男','女']"
                                    :value="userMsg.sex"
                                    solo
                                    :label="userMsg.sex"
                                    v-model="userMsg.sex"
                            ></v-select>
                        </div>
                    </div>
                </v-col>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--邮箱-->
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2">邮箱</div>
                        <div class="input_width">
                            <v-text-field
                                    label="请输入"
                                    solo
                                    dense
                                    :value="userMsg.email"
                                    v-model="userMsg.email"
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--地址-->
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2">角色</div>
                        <div class="input_width">
                            <v-select
                                    :label="role"
                                    :value="userMsg.role"
                                    solo
                                    disabled
                            ></v-select>
                        </div>
                    </div>
                </v-col>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--手机-->
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2">手机</div>
                        <div class="input_width">
                            <v-text-field
                                    disabled
                                    label="请输入"
                                    solo
                                    dense
                                    :value="userMsg.phone"
                                    v-model="userMsg.phone"
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--工作地点-->
                    <div class="d-flex flex-row">
                        <div class="mr-4 mt-2 p_two">身份证</div>
                        <div class="input_width">
                            <v-text-field
                                    label="身份证号"
                                    solo
                                    dense
                                    :value="userMsg.identity"
                                    v-model="userMsg.identity"
                            ></v-text-field>
                        </div>
                    </div>
                </v-col>

            </v-row>
        </div>
        <div>
            <v-row class="bt_row mt-6">
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--密码修改-->
                    <v-btn
                            color="#42A5F5"
                            rounded
                            width="100px"
                            height="40px"
                            dark
                            style="font-weight: bold;font-size: 16px"
                            @click="editPassword">
                        密码修改
                    </v-btn>
                </v-col>
                <v-col
                        cols="12"
                        sm="6"
                        md="6"
                >
                    <!--    保存按钮-->
                    <v-btn
                            color="#42A5F5"
                            rounded
                            width="100px"
                            height="40px"
                            dark
                            style="font-weight: bold;font-size: 16px"
                            @click="saveBt">
                        保存修改
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <!--修改密码弹窗-->
        <v-dialog
                v-model="dialog"
                max-width="420px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">密码修改</span>
                </v-card-title>
                <v-card-text>
                    <el-form :model="updatePwd" :rules="rules" ref="updatePwd" label-width="100px"
                             class="demo-ruleForm">
                        <el-form-item label="旧密码" prop="oldPassword">
                            <el-input v-model="updatePwd.oldPassword" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="新密码" prop="newPassword1">
                            <el-input v-model="updatePwd.newPassword1" show-password></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码" prop="newPassword2">
                            <el-input v-model="updatePwd.newPassword2" show-password></el-input>
                        </el-form-item>
                    </el-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="cancle"
                    >
                        取消
                    </v-btn>
                    <v-btn
                            color="blue darken-1"
                            text
                            @click="commitPwd">

                        提交
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {personalInformation, updateMsg, updatePwd, upLoad} from "@/network/Details/general_page/personal";
    import {deleteUser} from "@/network/Details/Ordinary_admin/userManage_O";

    export default {

        name: "Personal",
        data() {
            var validatePass1 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入新密码'));
                } else if (value.length < 6) {
                    callback(new Error('密码长度不能小于6！'));
                } else {
                    if (this.updatePwd.newPassword2 !== '') {
                        this.$refs.updatePwd.validateField('newPassword2');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.updatePwd.newPassword1) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                userId: window.localStorage.getItem("userId"),
                role: '',
                imageUrl: '',
                username: '',
                updateList: {
                    sex: '',
                    phone: '',
                    userName: '',
                    birthday: '',
                    email: '',
                    role: '',
                    identity: '',
                },

                //表单验证
                updatePwd: {
                    newPassword1: '',
                    newPassword2: '',
                    oldPassword: ''
                },
                rules: {
                    newPassword1: [
                        {validator: validatePass1, trigger: 'blur', required: true}
                    ],
                    newPassword2: [
                        {validator: validatePass2, trigger: 'blur', required: true}
                    ],
                    oldPassword: [{
                        required: true, message: '请输入旧密码', trigger: 'blur'
                    }]
                },
                //密码显示
                show: false,
                dialog: false,
                userMsg: {},
                //提示
                snackbarShow: false,
                snackbarText: '',
                snackbarColor: '#4CAF50',
                personalPhone: '',
            }
        },
        created() {
            this.personalPhone = window.localStorage.getItem("phone");
            personalInformation({
                phone: this.personalPhone
            }).then(res => {
                if (res.status === 200) {
                    this.userMsg = res.data;
                    this.imageUrl = res.data.avatarUrl
                    if (this.userMsg.sex === 1) {
                        this.userMsg.sex = '男'
                    } else {
                        this.userMsg.sex = '女'
                    }
                    this.role = res.data.role[0]
                    this.updateList.sex = this.userMsg.sex;
                    this.updateList.phone = this.userMsg.phone;
                    this.updateList.userName = this.userMsg.userName;
                    this.updateList.birthday = this.userMsg.birthday;
                    this.updateList.email = this.userMsg.email;
                    this.updateList.identity = this.userMsg.identity
                }
            })
        },
        methods: {
            upLoadAvatar(file) {
                let form = new FormData();
                form.append("userID", this.userId)
                form.append("avatar", file.file)
                upLoad(form).then(res => {
                    this.imageUrl = res.data.url
                })
            },
            handleAvatarSuccess(res, file) {
                this.imageUrl = URL.createObjectURL(file.raw);
                this.$message.success('头像上传成功!');
            },
            beforeAvatarUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 JPG 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            //保存
            saveBt() {
                this.$confirm('是否更改个人信息?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let data = {};
                    data.phone = this.personalPhone;
                    let flag = 0;
                    if (this.updateList.sex != this.userMsg.sex) {
                        if (this.userMsg.sex === '男') {
                            data.sex = 1;
                        } else {
                            data.sex = 0;
                        }
                        flag++;
                    }
                    if (this.updateList.email != this.userMsg.email) {
                        data.email = this.userMsg.email;
                        flag++;
                    }
                    if (this.updateList.birthday != this.userMsg.birthday) {
                        data.birthday = this.userMsg.birthday;
                        flag++;
                    }
                    if (this.updateList.userName != this.userMsg.userName) {
                        data.userName = this.userMsg.userName;
                        flag++;
                    }
                    if (this.updateList.identity != this.userMsg.identity) {
                        data.identity = this.userMsg.identity;
                        flag++;
                    }
                    if (flag > 0) {
                        updateMsg(data).then(res => {
                            console.log(res)
                            if (res.status === 200) {
                                this.$message({
                                    type: 'success',
                                    message: '更改成功!'
                                });
                            }
                        })
                    }

                }).catch(() => {
                    this.userMsg.userName = this.updateList.userName
                    this.userMsg.birthday = this.updateList.birthday
                    this.userMsg.email = this.updateList.email
                    this.userMsg.identity = this.updateList.identity
                    this.userMsg.sex = this.updateList.sex
                    this.userMsg = this.updateList
                    this.$message({
                        type: 'info',
                        message: '已取消更改!'
                    });
                });

            },
            //密码修改
            editPassword() {
                this.dialog = true;
            },

            commitPwd() {
                if (this.updatePwd.oldPassword === this.updatePwd.newPassword1) {
                    this.dialog = false
                    this.snackbarText = "新旧密码一致，修改失败！";
                    this.snackbarShow = true;
                } else {
                    this.$refs.updatePwd.validate((v) => {
                        if (v) {
                            updatePwd({
                                phone: this.personalPhone,
                                oldPassword: this.updatePwd.oldPassword,
                                password: this.updatePwd.newPassword2
                            }).then(res => {
                                if (res.status === 200) {

                                    this.snackbarText = '密码修改成功！',
                                        this.snackbarColor = '#4CAF50',
                                        this.snackbarShow = true
                                } else {
                                    this.snackbarText = '原密码错误，修改失败'
                                    this.snackbarColor = '#F44336'
                                    this.snackbarShow = true
                                }
                            })
                            this.dialog = false
                        } else {
                            return false
                        }
                    })
                }
            },
            cancle() {
                this.dialog = false,
                    this.$refs.updatePwd.resetFields()
            }
        }
    }
</script>

<style scoped lang="less">
    .container {
        background-color: white;
        width: 80%;
        height: 700px;
        margin-left: 10%;
        margin-top: 2%;
        display: flex;
        flex-direction: column;

        .msg_container {
            margin-left: 3%;
            margin-top: 20px;

            .icon {
                margin-top: 10px;
                display: flex;
                align-items: center;
            }
        }
    }

    .input_width {
        width: 300px;
    }

    .bt_row {
        margin-left: 20%;
    }

    .p_two {
        margin-top: 0px !important;
        width: 34px;
    }

    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        border: 1px solid #8C939D;
    }

    .avatar {
        width: 100px;
        height: 100px;
        display: block;
    }
</style>
